import React, { ChangeEvent } from "react";
import { Label } from "./_fieldStyles";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";

type RadioButtonFieldProps = {
  label?: string;
  name: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  handleBlur?: () => void;
  options?: string[];
  helperText?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RadioButtonField: React.FC<RadioButtonFieldProps> = (props) => {
	return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">{props.label}</FormLabel>

        {props.options?.map((opt, index) => {
          return (
            <Label key={index}>
              <input
                type="radio"
                name={props.name}
                value={opt}
                checked={opt === props.value}
                onBlur={props.handleBlur}
                onChange={props.onChange}
                disabled={props.disabled}
              />
              {opt}
            </Label>
          );
        })}
      </FormControl>
      <FormHelperText>{props.helperText}</FormHelperText>
      {props.error && (
        <FormHelperText error={true}>{props.error}</FormHelperText>
      )}
    </>
  );
};

RadioButtonField.defaultProps = {
  label: "Radio",
  name: "",
  options: ["Option 1" , "Option 2" ],
  error: "",
  helperText: "Helper text",
};

export default RadioButtonField;
