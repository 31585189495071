export const documentData = [
	{
		id: "remark",
		label: "Remark",
		placeholder: "",
		type: "textarea",
		helperText: "Any remark or additional information",
		validationType: "string",
		value: "",
		validations: [],
	},
	{
		id: "photo",
		label: "Photo *",
		placeholder: "please upload passport size photograph",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["photo is required"],
			},
		],
	},
	{
		id: "residenceProof",
		label: "Address Proof *",
		placeholder: "Electricity bill , Rent aggrement, voter id card etc.",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Address proof is required"],
			},
		],
	},
	{
		id: "markSheetCopyProof",
		label: "Degree Certificate *",
		placeholder: "Degree Certificate",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Degree Certificate is required"],
			},
		],
	},
	{
		id: "additionalDocs1",
		label: "Additional Document 1",
		placeholder:
			"Any document that does not exist above and is an additional ask by SVGA",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
	},
	{
		id: "additionalDocs2",
		label: "Additional Document 2",
		placeholder:
			"Any document that does not exist above and is an additional ask by SVGA",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
	},
];
