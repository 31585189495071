import React, { useRef } from "react";
import { Formik, FormikProps, FormikHelpers } from "formik";
import * as yup from "yup";
import { Fields } from "./components";
import { createYupSchema } from "./utils/yupSchemaCreator";
import { FIELD_TYPES, VALIDATION_TYPES } from "./constants";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Template } from "../models";
import ResponsiveTypography from "../components/ResponsiveTypography";

type FormValues = Record<string, any>;
interface TemplateRendererProps {
	template: Template;
	cbSubmit: (values: Record<string, any>) => void;
	formId: string;
	submitFormRef: any;
	formValues: FormValues;
	id?: string;
	profile?: any;
}

const TemplateRenderer: React.FC<TemplateRendererProps> = ({
	cbSubmit,
	template,
	formId,
	submitFormRef,
	profile,
	formValues,
}) => {
	const fields = template.fields;
	const initialValues: Record<string, any> = {};

	fields.forEach((item) => {
		if (!formValues[item.id] && !!item.defaultValue) {
			formValues[item.id] = item.defaultValue;
		}
	});

	const yupSchema = fields.reduce(createYupSchema, {});
	const validationSchema = yup.object().shape(yupSchema);

	return (
		<Card
			sx={{
				mb: 0.5,
				borderRadius: 1,
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<CardContent>
				{template.title && (
					<ResponsiveTypography>
						{"Template title " + template.title}
					</ResponsiveTypography>
				)}
				<Formik
					enableReinitialize={true}
					initialValues={formValues}
					validationSchema={validationSchema}
					onSubmit={(
						values: Record<string, any>,
						formikHelpers: FormikHelpers<Record<string, any>>
					) => {
						cbSubmit(values);
						// formikHelpers.resetForm();
					}}
				>
					{(formikProps: FormikProps<Record<string, any>>) => {
						submitFormRef.current = formikProps.submitForm;

						return (
							<form onSubmit={formikProps.handleSubmit}>
								<Grid container spacing={1.5}>
									<Fields
										fields={fields}
										formikProps={formikProps}
										formId={formId}
										profile={profile}
									/>
								</Grid>
							</form>
						);
					}}
				</Formik>
			</CardContent>
		</Card>
	);
};

export default TemplateRenderer;
