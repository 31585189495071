import React from "react";
import jsonLogic from "json-logic-js";
import Textfield from "./Textfield";
import SelectField from "./SelectField";
import TextAreaField from "./TextAreaField";
import RadioButtonField from "./RadioButtonField";
import CheckboxField from "./CheckboxField";
import UploadField from "./UploadField";
import DateSelector from "./DateSelector";
import Text from "./Text";
import CondSelectField from "./CondSelectField";
import { FormikProps } from "formik";
import ImageHolder from "./ImageHolder";
import { Grid } from "@mui/material";
import BlankField from "./BlankField";
import { AdditionalSettings } from "../../models/FormField";

type CommonProps = {
	formId?: string;
	label?: string;
	name?: string;
	type: any;
	options: any;
	placeholder?: string;
	value?: string;
	touched?: boolean;
	error?: string | boolean;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (e: React.ChangeEvent<any>) => void;
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => void;
	helperText?: string;
	rules?: any;
	rules_data?: any;
	disabled?: boolean;
	alt?: string;
	image?: string;
	title?: string;
	additionalSettings?: AdditionalSettings | undefined;
};

type SelectProps = CommonProps & {};

const fieldMap: { [key: string]: React.FC<CommonProps> } = {
	text: Textfield as React.FC<CommonProps>,
	select: SelectField as React.FC<SelectProps>,
	textarea: TextAreaField as React.FC<CommonProps>,
	radio: RadioButtonField as React.FC<CommonProps>,
	checkbox: CheckboxField as React.FC<CommonProps>,
	upload: UploadField as React.FC<CommonProps>,
	materialuipicker: DateSelector as React.FC<CommonProps>,
	date: Textfield as React.FC<CommonProps>,
	statictext: Text as React.FC<CommonProps>,
	condSelect: CondSelectField as React.FC<CommonProps>,
	// imageHolder: ImageHolder as React.FC<CommonProps>,
};

type FieldProps = {
	fields: any[];
	formikProps: FormikProps<any>;
	formId?: string;
	profile?: any;
};

const Fields: React.FC<FieldProps> = ({
	fields,
	formikProps,
	formId,
	profile,
}) => {
	const {
		errors,
		touched,
		values,
		submitCount,
		handleBlur,
		handleChange,
		setFieldValue,
	} = formikProps;
	let data_temp: { [key: string]: any } = {};

	fields.forEach((item) => {
		data_temp[item.id] = values[item.id];
	});

	const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		handleBlur(e);
	};

	const setFieldValueProxy = (id: string, value: string) => {
		setFieldValue(id, value);
	};

	// Action processor
	const processActions = (actions: any[], item: any) => {
		if (!actions || !Array.isArray(actions)) return;

		let finalValue = values[item.id]; // Start with the current value

		actions.forEach((action) => {
			if (!action || typeof action !== "object") {
				console.error(
					"Ignored!! ",
					item.id,
					"using older way for action",
					action
				);
				return;
			}

			switch (action.type) {
				case "reference":
					// Copy value from another field
					if (
						action.field &&
						values[action.field] !== undefined &&
						(finalValue !== "" || finalValue !== undefined) &&
						finalValue !== values[action.field]
					) {
						finalValue = values[action.field];
					}
					break;

				case "required":
					// Set default value if not present, more of bypass

					if (finalValue === "" || finalValue === undefined) {
						finalValue = "NA&&";
					}
					break;

				case "reset":
					// Reset to a default value
					if (action.defaultValue !== undefined) {
						finalValue = action.defaultValue;
					}
					break;

				case "append":
					// Append a string to the current value
					if (
						action.field &&
						values[action.field] !== undefined &&
						(finalValue !== "" || finalValue !== undefined) &&
						finalValue !== values[action.field]
					) {
						finalValue += " " + values[action.field]; // Append action.value to the current value
					}
					break;

				default:
					console.warn(`Unknown action type: ${action.type}`);
			}
		});
		// Update the field value once with the final computed value
		if (finalValue !== values[item.id]) {
			setFieldValueProxy(item.id, finalValue);
		}
	};

	return fields.map((item, index) => {
		if (!item.type) {
			throw new Error(
				`Component type ${item.type} is not supported by flexi-form`
			);
		}
		const Component = fieldMap[item.type];
		if (!Component) {
			throw new Error(
				`Component type ${item.type} is not supported by flexi-form`
			);
		}
		let touch = touched.hasOwnProperty(item.id) && touched[item.id];
		let error =
			submitCount > 0 && errors.hasOwnProperty(item.id)
				? errors[item.id]
				: false;

		// Evaluate conditional logic using jsonLogic
		const condition = jsonLogic.apply(item.rules, data_temp);

		if (!condition) {
			// If condition fails, apply the actions for "required" handling
			processActions(item.action, item);
			return (
				<Grid item xs={12} sm={item.elementWidth} key={index}>
					<BlankField />
				</Grid>
			);
		}

		// Process the actions sequentially
		if (item.action) {
			processActions(item.action, item);
		}

		let disabled = false;
		if (profile != undefined) {
			if (
				profile[item.id] !== undefined &&
				(values[item.id] === undefined || values[item.id] === "")
			) {
				setFieldValueProxy(item.id, profile[item.id].value);
			}
			if (profile[item.id] !== undefined) {
				if (profile[item.id].submitted === true) {
					disabled = !profile[item.id].isEditable;
				}
			}
		}
		return (
			<Grid key={index} item xs={12} sm={item.elementWidth}>
				<Component
					key={index}
					formId={formId}
					label={item.label}
					type={item.type}
					name={item.id}
					placeholder={item.placeholder}
					value={values[item.id]}
					options={item.options}
					touched={touch as boolean}
					error={error as string | boolean}
					handleBlur={onBlurHandler}
					onChange={handleChange}
					setFieldValue={setFieldValueProxy}
					helperText={item.helperText}
					rules={item.rules}
					rules_data={data_temp}
					disabled={disabled}
					additionalSettings={item.additionalSettings}
				/>
			</Grid>
		);
	});
};

export {
	Fields,
	Text,
	Textfield,
	SelectField,
	TextAreaField,
	RadioButtonField,
	CheckboxField,
	UploadField,
	DateSelector,
	CondSelectField,
};

// modify processActions such that setFieldValueProxy is called once at the end with the final values that went through all the actions
