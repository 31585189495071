// RazorpayButton.js
import React from "react";

const RazorpayButton = (props) => {
	const { transaction, checkOutSuccessCallback, updateCheckOutFailure } =
		props;
	const loadRazorpayScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => resolve(true);
			script.onerror = () => resolve(false);
			document.body.appendChild(script);
		});
	};

	const handlePayment = async () => {
		const res = await loadRazorpayScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert(
				"Failed to load Razorpay SDK. Please check your internet connection."
			);
			return;
		}

		const options = {
			key: "rzp_test_mzkxJeouuYmoPk", // Replace with your Razorpay Key ID
			amount: transaction.amount, // 50000 paise = INR 500.00
			currency: transaction.currency,
			name: "SVGA",
			description: "Test Transaction",
			// image: "https://example.com/your_logo",
			order_id: transaction.order_id, // Replace with your order ID
			handler: function (response) {
				checkOutSuccessCallback(response);
			},
			prefill: {
				name: "Kumar Dedhia",
				email: "kumded@gmail.com",
				contact: "9768420378",
			},
			notes: {
				address: "SVGA",
			},
			theme: {
				color: "#3399cc",
			},
		};

		const rzp = new window.Razorpay(options);
		rzp.on("payment.failed", function (response) {
			updateCheckOutFailure(response);
			// alert("Error Code: " + response.error.code);
			// alert("Description: " + response.error.description);
			// alert("Source: " + response.error.source);
			// alert("Step: " + response.error.step);
			// alert("Reason: " + response.error.reason);
			// alert("Order ID: " + response.error.metadata.order_id);
			// alert("Payment ID: " + response.error.metadata.payment_id);
		});

		rzp.open();
	};

	return (
		<button
			onClick={handlePayment}
			style={{ padding: "10px 20px", fontSize: "16px" }}
		>
			Proceed to Pay
		</button>
	);
};

export default RazorpayButton;
// blend this to the above logic
