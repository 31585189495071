import moment from "moment";
import { ApplicationForm, CLAIMS, Template } from "@anginn/flexi-form";

import { ScDeclaration } from "@form-data/Scholorship/ScDeclaration";
import { ScPersonalData } from "@form-data/Scholorship/ScPersonalData";
import { ScBankData } from "@form-data/Scholorship/ScBankData";
import { ScDocumentData } from "@form-data/Scholorship/ScDocumentData";
import { ScRuleRegData } from "@form-data/Scholorship/ScRuleRegData";

const approvals = {
	leaves: [
		{
			leafId: "1one",
			name: "Form Check",
			allowedClaims: [CLAIMS.ADMIN.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
			allowReapprovalUponDecline: true,
			allowEditingUponDecline: true,
		},
		{
			leafId: "2two",
			name: "Internal Audit",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
		{
			leafId: "3three",
			name: "Delivered",
			allowedClaims: [CLAIMS.ADMIN.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
	],
};

const formData = {
	id: "ssapr24",
	name: "Scholarship Application Form",
	subtitle: "School students, Scheme - Apr 2024",
	headerImage: "headerImage.jpg",
	templates: {
		0: {
			name: "Self Declaration",
			template: new Template("sdf3", ScDeclaration),
			preview: false,
		},
		1: {
			name: "Personal Details",
			template: new Template("sdwer", ScPersonalData),
			preview: true,
		},
		2: {
			name: "Bank Details",
			template: new Template("sfrw", ScBankData),
			preview: true,
		},
		3: {
			name: "Document Upload",
			template: new Template("wrvwr", ScDocumentData),
			preview: true,
		},
	},
	start_time: moment("25/3/2024 00:00", "DD/MM/YYYY HH:mm"),
	end_time: moment("30/4/2025 23:59", "DD/MM/YYYY HH:mm"),
	pretext: ScRuleRegData,
	approvals: approvals,
};
const getApplication = () => {
	const temp = Object.assign(new ApplicationForm(), formData);
	temp.parseObject();
	return temp;
};

export const form = getApplication;
