const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const checkout = [
	{
		id: "asdfasd",
		label: "Name",
		type: "statictext",
		validationType: "string",
		value: "",
		isEditableProfile: false,
		action: [
			{ type: "reference", field: "name" },
			{ type: "append", field: "fatherName" },
			{ type: "append", field: "lastName" },
		],
	},
	{
		id: "sdfsg",
		label: "Email",
		placeholder: "Enter Amount to Donate",
		type: "statictext",
		validationType: "string",
		value: "",
		action: [{ type: "reference", field: "email" }],
	},
	{
		id: "dsfgasd",
		label: "Life Membership Fee",
		type: "statictext",
		validationType: "string",
		value: "",
		defaultValue: "2500",
		// action: [{ type: "reference", field: "donationAmt" }],
	},
	{
		id: "sdsfg",
		label: "",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "Information provided cannot be modified upon clicking 'Confirm & Submit' button, Verify all details before proceeding.",
		validations: [],
		preview: false,
	},
	{
		id: "sdfv",
		label: "Approvals & Payment Criteria",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "Life membership is now subject to approval by SVGA, Once approved payment can be made visiting the same page.",
		validations: [],
		preview: false,
	},
	{
		id: "membershipDeclaration1",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"I hereby apply for the Membership of Shree Vagad Graduates’ Association. I hereby also agree to abide by the rules and regulation and registrations of the Association.",
		],
		validations: [
			{
				type: "required",
				params: ["Self declaration is required to proceed"],
			},
		],
	},
];
