import { LOGOUT } from "../actions/auth";

import { form as senfol } from "@form-data/forms-templates/laptop_senfol";
import { form as ssapr23 } from "@form-data/forms-templates/scholarship_apr23";
import { form as ssapr24 } from "@form-data/forms-templates/scholarship_apr24";
import { form as jsjun23 } from "@form-data/forms-templates/jsjun23";
import { form as recruiterjun23 } from "@form-data/forms-templates/recruiterjun23";
import { form as laju23 } from "@form-data/forms-templates/laptop_laju23";
import { form as laptop23s7p2 } from "@form-data/forms-templates/laptop23_s7p2";
import { form as laptop24s8p1 } from "@form-data/forms-templates/laptop24_s8p1";
import { form as donation2024 } from "@form-data/forms-templates/donation_2024";
import { form as membershipregistration } from "@form-data/forms-templates/membershipregistration";
import { form as ofgjL } from "@form-data/forms-templates/laptop_ofgjL";
// import { form as testform } from "@form-data/forms-templates/scholarship_d56f4g";

// Two copies of the same are mentioned for resolving the logout issue for 2nd user.
// IMP : should always create a new object and never refer already generated value. Could be a nightmare.
const initialState = {
	applications: {
		// dkfjg: dkfjg,
		// d56f4g: d56f4g,
		// lfkgk: lfkgk,
		// ofgjL: ofgjL, // has approval config.
		// senfol: senfol,
		// ssapr23: ssapr23(),
		// ssapr24: ssapr24(),
		// laptop24s8p1: laptop24s8p1(),
		// donation2024: donation2024(),
		membershipregistration: membershipregistration(),
		// jsjun23: jsjun23(),
		// recruiterjun23: recruiterjun23(),
		// laju23: laju23,
		// laptop23s7p2: laptop23s7p2,
		// testform: testform,
	},
};

/*
`_` is not allowed in formId or aadhar(applicantId)
this causes issue during sending email.
*/

export default (state = { ...initialState }, action) => {
	switch (action.type) {
		case LOGOUT:
			console.log("LOGOUT in forms");
			return {
				applications: {
					// dkfjg: dkfjg,
					// d56f4g: d56f4g,
					// lfkgk: lfkgk,
					// ofgjL: ofgjL,
					// senfol: senfol,
					// ssapr23: ssapr23(),
					// ssapr24: ssapr24(),
					// laptop24s8p1: laptop24s8p1(),
					// donation2024: donation2024(),
					membershipregistration: membershipregistration(),
					// jsjun23: jsjun23(),
					//   recruiterjun23: recruiterjun23(),
					// laju23: laju23,
					// laptop23s7p2: laptop23s7p2,
					// testform: testform,
				},
			};
		default:
			return state;
	}
};
