export const FIELD_TYPES = {
	TEXT: "text",
	SELECT: "select",
	TEXTAREA: "textarea",
	RADIO: "radio",
	CHECKBOX: "checkbox",
	UPLOAD: "upload",
	DATE: "date",
	MATERIAL_UI_PICKER: "materialuipicker",
	STATIC_TEXT: "statictext",
	COND_SELECT: "condSelect",
};

export const VALIDATION_TYPES = {
	STRING: "string",
	NUMBER: "number",
	BOOLEAN: "boolean",
	DATE: "date",
	ARRAY: "array",
	OBJECT: "object",
	FILE: "file",
};
