import moment from "moment";
import { ApplicationForm, Template, CLAIMS } from "@anginn/flexi-form";

import { pg1 } from "@form-data/Membership/personalData";
import { pg2 } from "@form-data/Membership/qualifications";
import { documentData } from "@form-data/Membership/documentData";
import { checkout } from "@form-data/Membership/checkout";
import { RuleRegData } from "@form-data/Membership/rulesRegData";

const approvals = {
	leaves: [
		{
			leafId: "1one",
			name: "Form Check",
			allowedClaims: [CLAIMS.ADMIN.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
			allowReapprovalUponDecline: true,
			allowEditingUponDecline: true,
		},
		{
			leafId: "2two",
			name: "Accept membership request",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
	],
};

const formData = {
	id: "membershipregistration",
	name: "Membership Registration Form",
	subtitle: "Register to become SVGA's lifetime member",
	// headerImage: "LaptopBanner3.png",
	templates: {
		0: {
			name: "Personal Details",
			template: new Template("gfrt", pg1, "", "Next"),
			preview: true,
		},
		1: {
			name: "Academics and Profession",
			template: new Template("gf34t", pg2, "", "Next"),
			preview: true,
		},
		2: {
			name: "Document Upload",
			template: new Template(
				"dfsg",
				documentData,
				"",
				"Proceed to Checkout"
			),
			preview: true,
		},
		3: {
			name: "Check Out",
			template: new Template("gfrt", checkout, "", "Confirm & Submit"),
			preview: true,
		},
	},
	start_time: moment("26/7/2024 00:00", "DD/MM/YYYY HH:mm"),
	// end_time: moment("4/8/2026 23:59", "DD/MM/YYYY HH:mm"),
	pretext: RuleRegData,
	approvals: approvals,
};

const getApplication = () => {
	const temp = Object.assign(new ApplicationForm(), formData);
	temp.parseObject();
	return temp;
};

export const form = getApplication;
