import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Typography } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "./App.css";
import Root from "./root";
import LandingPage from "./Pages/LandingPage2";
import SignIn from "./Pages/login";
import ProtectedRoute from "./components/commons/ProtectedRoute";
import FormPreview from "./Pages/FormPreview";
import { useSelector, useDispatch } from "react-redux";
import theme from "./theme";

import * as authActions from "./store/actions/auth";
import ChangeContact from "./Pages/changeContact";
import PolicyPage from "./Pages/PolicyPage";
import CheckoutPage from "./Pages/CheckOutPage";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const forms = useSelector((state) => state.forms.applications);
	const autoLoginCount = useSelector((state) => state.auth.autoLogin);

	const dispatch = useDispatch();

	useEffect(() => {
		const tryLogin = () => {
			const userId = localStorage.getItem("id");
			const token = localStorage.getItem("idToken");
			const refreshToken = localStorage.getItem("refreshToken");
			const expiryDate = localStorage.getItem("expiryDate");
			const expirationDate = new Date(expiryDate);

			if (expirationDate <= new Date() || !token || !userId) {
				// props.navigation.navigate("Auth");
				// return;
			}

			dispatch(authActions.authenticate(userId, token, refreshToken));
		};
		tryLogin();
	}, [dispatch]);

	useEffect(() => {
		if (autoLoginCount !== 0) {
			setIsLoading(false);
		}
	}, [autoLoginCount]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<React.Fragment>
					{isLoading ? (
						<Typography>Loading</Typography>
					) : (
						<Routes>
							<Route
								path="/home"
								element={
									<ProtectedRoute component={LandingPage} />
								}
							/>
							{Object.keys(forms).map((id) => (
								<Route
									key={id}
									path={`/${id}`}
									element={
										<ProtectedRoute component={Root} />
									}
								/>
							))}
							<Route path="/login" element={<SignIn />} />
							<Route
								path="/changeContact"
								element={<ChangeContact />}
							/>
							<Route
								path="/FormPreview"
								element={<FormPreview />}
							/>
							<Route
								path="/checkout"
								element={<CheckoutPage />}
							/>
							<Route path="/policy" element={<PolicyPage />} />
							<Route path="/" element={<Navigate to="/home" />} />
							<Route
								path="*"
								element={<div>404 not found</div>}
							/>
						</Routes>
					)}
				</React.Fragment>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
