import { Transaction } from "firebase/firestore";
import { SET_PAYMENTS, SET_USER, UPDATE_TEMPLATE } from "../actions/applicant";
import { LOGOUT } from "../actions/auth";

const initialState = {
	userId: "",
	applicant: undefined,
	payments: undefined,
	transaction: undefined,
};

const states = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_TEMPLATE:
			return {
				...state,
				applicant: action.applicant,
			};
		case SET_USER:
			return {
				...state,
				userid: action.id,
			};
		case SET_PAYMENTS:
			return {
				...state,
				payments: action.payments,
				transaction: action.transaction,
			};
		case LOGOUT:
			console.log("LOGOUT in applicant");
			return initialState;
		default:
			return state;
	}
};

export default states;
