import { PaymentTransaction, TransactionResponse } from "./PaymentTransaction";

class PaymentHandler {
	constructor(
		public transactions: PaymentTransaction[] = [],
		public allowMultipleTransactions: boolean
	) {}

	createTransaction(
		applicantId: string,
		formId: string,
		amount: number,
		currency: string
	): PaymentTransaction {
		if (amount <= 0) {
			throw new Error("Amount must be positive.");
		}
		if (!/^[A-Z]{3}$/.test(currency)) {
			throw new Error("Invalid currency code.");
		}
		if (!this.allowMultipleTransactions && this.transactions.length > 0) {
			throw new Error("Multiple transactions are not allowed.");
		}

		const transactionId = this.generateTransactionId();
		const transaction = new PaymentTransaction(
			transactionId,
			applicantId,
			formId,
			amount,
			currency
		);

		this.transactions = [...this.transactions, transaction]; // Immutable addition
		console.log(`Transaction created: ${transactionId}`);
		return transaction;
	}

	updatePlacedOrder(
		transactionId: string,
		response: TransactionResponse
	): void {
		const index = this.transactions.findIndex(
			(txn) => txn.transactionId === transactionId
		);

		if (index === -1) {
			throw new Error(`Transaction with ID ${transactionId} not found.`);
		}

		// Create a new updated transaction immutably
		const updatedTransaction = PaymentTransaction.fromJSON({
			...this.transactions[index].toJSON(),
		});

		updatedTransaction.updatePlacedOrder(response);

		// Replace the old transaction immutably
		this.transactions = [
			...this.transactions.slice(0, index),
			updatedTransaction,
			...this.transactions.slice(index + 1),
		];

		console.log(`Transaction updatePlacedOrder updated: ${transactionId}`);
	}

	updateCheckOutSuccess(
		transactionId: string,
		response: TransactionResponse
	): void {
		const index = this.transactions.findIndex(
			(txn) => txn.transactionId === transactionId
		);

		if (index === -1) {
			throw new Error(`Transaction with ID ${transactionId} not found.`);
		}

		// Create a new updated transaction immutably
		const updatedTransaction = PaymentTransaction.fromJSON({
			...this.transactions[index].toJSON(),
		});

		updatedTransaction.updateCheckOutSuccess(response);

		// Replace the old transaction immutably
		this.transactions = [
			...this.transactions.slice(0, index),
			updatedTransaction,
			...this.transactions.slice(index + 1),
		];

		console.log(
			`Transaction updateCheckOutSuccess updated: ${transactionId}`
		);
	}

	updateCheckOutFailure(
		transactionId: string,
		response: TransactionResponse
	): void {
		const index = this.transactions.findIndex(
			(txn) => txn.transactionId === transactionId
		);

		if (index === -1) {
			throw new Error(`Transaction with ID ${transactionId} not found.`);
		}

		// Create a new updated transaction immutably
		const updatedTransaction = PaymentTransaction.fromJSON({
			...this.transactions[index].toJSON(),
		});

		updatedTransaction.updateCheckOutFailure(response);

		// Replace the old transaction immutably
		this.transactions = [
			...this.transactions.slice(0, index),
			updatedTransaction,
			...this.transactions.slice(index + 1),
		];

		console.log(
			`Transaction updateCheckOutFailure updated: ${transactionId}`
		);
	}

	recordCheckOut(transactionId: string, response: TransactionResponse) {}

	getTransaction(transactionId: string): PaymentTransaction | undefined {
		return this.transactions.find(
			(txn) => txn.transactionId === transactionId
		);
	}

	getLastPendingTransaction(): PaymentTransaction | undefined {
		return [...this.transactions]
			.reverse() // Check the latest transactions first
			.find((txn) => ["INITIATED", "ORDER_PLACE"].includes(txn.status));
	}

	toJSON(): object {
		return {
			transactions: this.transactions.map((txn) => txn.toJSON()),
			allowMultipleTransactions: this.allowMultipleTransactions,
		};
	}

	static fromJSON(json: any): PaymentHandler {
		const transactions = (json.transactions || []).map((txn: any) =>
			PaymentTransaction.fromJSON(txn)
		);
		return new PaymentHandler(transactions, json.allowMultipleTransactions);
	}

	private generateTransactionId(): string {
		return `txn_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
	}

	hash(): string {
		const jsonString = JSON.stringify(
			this.transactions.map((txn) => txn.toJSON()), // Hash transactions
			Object.keys(this).sort()
		);

		let hash = 0;
		for (let i = 0; i < jsonString.length; i++) {
			const char = jsonString.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash |= 0; // Convert to 32-bit integer
		}

		return `hash_${hash}`;
	}
}

export { PaymentHandler };
