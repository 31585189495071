import { Approvals } from "./Approvals";
import { ApproverResponse, ApproversResponses } from "./ApproversResponses";

class ApplicationFormApprovalsMixin {
	public approvals?: Approvals;

	private approvalsStepCount?: number;

	setApproversResponses(approversResponses: ApproversResponses) {
		if (this.approvals !== undefined) {
			this.approvals.responses = approversResponses;
			this.approvals.parseObject();
		}
	}

	addApproverResponse(approverResponse: ApproverResponse) {
		if (this.approvals && this.approvals.responses) {
			this.approvals.responses.updateApproversResponse(approverResponse);
		}
	}

	getApprovalsStepCount(): number {
		if (
			this.approvalsStepCount === undefined &&
			this.approvals !== undefined
		) {
			this.approvalsStepCount = this.approvals.getTtlSteps();
		}
		return this.approvalsStepCount || 0;
	}

	parseApprovals() {
		if (this.approvals !== undefined) {
			this.approvals = new Approvals(
				this.approvals.leaves,
				this.approvals.responses
			);
			this.approvals.parseObject();
		}
	}
}

export { ApplicationFormApprovalsMixin };
