import { format } from "date-fns";
import moment from "moment/moment";

export const convertDateToString = (date) => {
  return format(date, "yyyy-MM-dd HH:mm");
};

export const convertMomentDateToString = (date) => {
  return date.format("DD MMM YY h:mm A");
};

export const convertDateToStringForCoupon = (date) => {
  return format(date, "yyyy-MM-dd HH:mm");
};

export const parseISOformat = (datetime) => {
  return moment(datetime).format("DD/MM/YYYY hh:mm A");
};

/**
	 *                      +-------------+
							|    Server   |
							+-------------+
								|
								| sends date in ISO format
								V
							+----------------+
							|  Input Parser  |
							+----------------+
								|
								| converts ISO format to date object
								V
							+----------------+
							|  Formatter     |
							+----------------+
								|
								| formats date object as per component requirement
								V
							+----------------+
							|  Components    |
							+----------------+


	*/
