import React from "react";
import { useMediaQuery, Box } from "@mui/material";
import CustomMobileStepper from "./CustomMobileStepper";
import WebStepper from "./WebStepper";

interface DynamicStepperProps {
	children: React.ReactNode;
	totalSteps: number;
	activeStep: number;
	handleNext: () => void;
	handleBack: () => void;
	steps: string[];
	application_name: string;
	isStepOptional?: (step: number) => boolean;
	isStepSkipped?: (step: number) => boolean;
	nextButtonName: string;
	backButtonName: string;
}

const DynamicStepper: React.FC<DynamicStepperProps> = (props) => {
	const {
		children,
		totalSteps,
		activeStep,
		handleNext,
		handleBack,
		steps,
		application_name,
		nextButtonName,
		backButtonName,
		isStepOptional,
		isStepSkipped,
	} = props;

	const isMobile = useMediaQuery("(max-width:600px)");

	const getNextButtonName = () => {
		if (nextButtonName === "") {
			return activeStep === totalSteps - 1 ? "Finish" : "Next";
		}
		return nextButtonName;
	};
	const getBackButtonName = () => {
		if (backButtonName === "") {
			return "Back";
		}
		return backButtonName;
	};

	return (
		<>
			{isMobile ? (
				<CustomMobileStepper
					application_name={application_name}
					totalSteps={totalSteps}
					isStepperRequired={totalSteps > 1}
					activeStep={activeStep}
					handleNext={handleNext}
					handleBack={handleBack}
					nextButtonName={getNextButtonName()}
					backButtonName={getBackButtonName()}
				>
					{children}
				</CustomMobileStepper>
			) : (
				<WebStepper
					steps={steps}
					application_name={application_name}
					isStepperRequired={totalSteps > 1}
					activeStep={activeStep}
					handleNext={handleNext}
					handleBack={handleBack}
					isStepOptional={isStepOptional}
					isStepSkipped={isStepSkipped}
					nextButtonName={getNextButtonName()}
					backButtonName={getBackButtonName()}
				>
					{children}
				</WebStepper>
			)}
		</>
	);
};

export default DynamicStepper;
