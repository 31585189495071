// src/Form/index.tsx
import React, { useRef, useEffect, useState, useMemo } from "react";
import { FIELD_TYPES, VALIDATION_TYPES } from "./constants";
import TemplateRenderer from "./TemplateRenderer";
import DynamicStepper from "./DynamicStepper";
import { ApplicationForm } from "../models";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import ImageHolder from "./components/ImageHolder";
import { convertMomentDateToString } from "../utils/AppDateFormatter";
import moment, { Moment } from "moment";
import { fetchSignedUrl } from "../utils/SessionStorageManager";

interface DynamicFormProps {
	application: ApplicationForm;
	cbSubmit: (
		values: Record<string, any>,
		currentStep: number,
		isFinalSubmit: boolean
	) => void;
	formId: string;
	setIsValid: (isValid: boolean) => void;
	// onFinalSubmit?: () => void;
	FinalSubmitComponent?: React.ComponentType<{
		application: ApplicationForm;
	}>;
	// id?: string; not in use
	profile?: any;
	currentStep: number;
}

const DynamicForm: React.FC<DynamicFormProps> = (props) => {
	const {
		application,
		cbSubmit,
		formId,
		// onFinalSubmit,
		profile,
		FinalSubmitComponent,
		currentStep,
	} = props;

	const submitFormRef = useRef<() => void>(() => {});
	const templates = application.getTemplateList();
	const [activeStep, setActiveStep] = useState(currentStep);
	const [isFinalSubmit, setIsFinalSubmit] = useState(
		application.isFinalSubmit()
	);
	const [imageDownloadURL, setImageDownloadURL] = useState<string>("");

	// Memoize flattened values
	const flattenedValues = useMemo(() => {
		return application.getFlatternValues();
	}, [application]);

	useEffect(() => {
		const template = templates[activeStep];
		const keyOrFilename = application.getHeaderImage(template.id);

		fetchSignedUrl(keyOrFilename, "/api/get_s3_form_fileURI")
			.then((url) => {
				setImageDownloadURL(url);
			})
			.catch((error) => {
				console.error("Error retrieving URL:", error);
			});
	}, [activeStep, templates, application]);

	if (isFinalSubmit) {
		const submittedOn = application.submittedOn;

		if (FinalSubmitComponent) {
			return <FinalSubmitComponent application={application} />;
		} else {
			if (submittedOn) {
				return (
					"Form Submitted, We received your last response as of " +
					convertMomentDateToString(moment(submittedOn)) +
					" ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે"
				);
			}
			return "Form Submitted";
		}
	}

	const template = templates[activeStep];
	const totalSteps = templates.length;

	const cbSubmitTmp = (values: any) => {
		console.log("cbSubmitTmp");

		template.update_default_values(values);
		application.updateTemplate(activeStep, template);

		application.nextStep();
		application.submit();
		const ttlSteps = application.getStepList().length;

		const getCurrentStep = application.getCurrentStep();
		cbSubmit(values, getCurrentStep, application.activeStep === ttlSteps);
		setActiveStep(getCurrentStep);
		setIsFinalSubmit(application.isFinalSubmit());
	};

	const handleNext = () => {
		submitFormRef.current();
	};

	const handleBack = () => {
		application.prevStep();
		setActiveStep(application.getCurrentStep());
	};

	return (
		<Box
			sx={{
				minHeight: "100%",
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<ImageHolder alt="" image={imageDownloadURL} />
			<DynamicStepper
				application_name={application.name}
				totalSteps={totalSteps}
				activeStep={activeStep}
				handleNext={handleNext}
				handleBack={handleBack}
				steps={application.getStepList()}
				nextButtonName={
					template.nextButtonName === undefined
						? ""
						: template.nextButtonName
				}
				backButtonName={
					template.backButtonName === undefined
						? ""
						: template.backButtonName
				}
			>
				<TemplateRenderer
					cbSubmit={cbSubmitTmp}
					template={template}
					formId={formId}
					submitFormRef={submitFormRef}
					profile={profile}
					formValues={flattenedValues}
				/>
			</DynamicStepper>
		</Box>
	);
};

export { FIELD_TYPES, VALIDATION_TYPES, DynamicForm };
