import React from "react";
import { useDispatch } from "react-redux";
import {
	Container,
	Typography,
	Button,
	Card,
	CardContent,
	Grid,
	Divider,
	Box,
} from "@mui/material";
import { placePaymentOrder } from "../store/actions/applicant";

const CheckoutPage = () => {
	// Predefined donation and donor details
	const donationInfo = {
		name: "John Doe",
		email: "johndoe@example.com",
		amount: 50, // Fixed donation amount
	};

	const dispatch = useDispatch();

	const handleProceedToPayment = () => {
		// Mock URL for payment gateway redirection (replace with actual gateway URL)
		dispatch(placePaymentOrder("654632165843", "donation2024", 12000));
	};

	return (
		<Container maxWidth="sm">
			<Typography
				variant="h4"
				gutterBottom
				align="center"
				sx={{ marginTop: 3 }}
			>
				Confirm Your Donation
			</Typography>

			<Card
				variant="outlined"
				sx={{ marginY: 4, padding: 3, boxShadow: 3 }}
			>
				<CardContent>
					<Typography variant="h6" gutterBottom>
						Donor Information
					</Typography>
					<Grid container spacing={1} sx={{ marginBottom: 2 }}>
						<Grid item xs={4}>
							<Typography
								variant="subtitle1"
								color="textSecondary"
							>
								Full Name
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography variant="body1">
								{donationInfo.name}
							</Typography>
						</Grid>

						<Grid item xs={4}>
							<Typography
								variant="subtitle1"
								color="textSecondary"
							>
								Email
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography variant="body1">
								{donationInfo.email}
							</Typography>
						</Grid>
					</Grid>

					<Divider sx={{ marginY: 2 }} />

					<Typography variant="h6" gutterBottom>
						Donation Amount
					</Typography>
					<Typography
						variant="h4"
						color="primary"
						sx={{ marginBottom: 2 }}
					>
						${donationInfo.amount}.00
					</Typography>
				</CardContent>
			</Card>

			<Box textAlign="center" mt={4}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleProceedToPayment}
					sx={{ paddingX: 4, paddingY: 1.5, fontSize: "1.1rem" }}
				>
					Proceed to Payment
				</Button>
			</Box>
		</Container>
	);
};

export default CheckoutPage;
