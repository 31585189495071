import { SERVER_URL_IMG } from "../config";

interface SessionStorageData {
	url: string;
	expirationTime: number;
}

class SessionStorageManager {
	private apiUrl: string;

	constructor(apiUrl: string) {
		this.apiUrl = apiUrl;
	}

	// Helper function to get current timestamp in seconds
	private getCurrentTimestamp(): number {
		return Math.floor(Date.now() / 1000);
	}

	// Check if the URL is still valid based on expiration time
	private isExpired(expirationTime: number): boolean {
		const currentTime = this.getCurrentTimestamp();
		return currentTime > expirationTime;
	}

	// Fetch a signed URL from sessionStorage if available and not expired
	private getFromSessionStorage(key: string): string | null {
		const storedData = sessionStorage.getItem(key);

		if (storedData) {
			const parsedData: SessionStorageData = JSON.parse(storedData);

			if (!this.isExpired(parsedData.expirationTime)) {
				// console.log(`Found URL in sessionStorage for key: ${key}`);
				return parsedData.url;
			} else {
				console.log(
					`Expired URL found for key: ${key}, fetching a new one.`
				);
				sessionStorage.removeItem(key);
			}
		}

		return null;
	}

	// Store the signed URL and expiration time in sessionStorage
	private storeInSessionStorage(
		key: string,
		url: string,
		expirationTime: number
	): void {
		const dataToStore: SessionStorageData = {
			url,
			expirationTime,
		};
		sessionStorage.setItem(key, JSON.stringify(dataToStore));
		console.log(`Stored URL in sessionStorage for key: ${key}`);
	}

	// Fetch a signed URL from the server via an API
	private async fetchFromApi(keyOrFilename: string): Promise<string> {
		const response = await fetch(
			`${SERVER_URL_IMG}/${this.apiUrl}/${keyOrFilename}`
		);

		if (!response.ok) {
			throw new Error(`Failed to fetch URL: ${response.statusText}`);
		}

		const data: { url: string } = await response.json();
		return data.url;
	}

	// Validate input parameters to ensure they are non-empty strings
	private areValidParams(
		aadhaarNo: string,
		formId: string,
		filename: string
	): boolean {
		return (
			aadhaarNo.trim() !== "" &&
			formId.trim() !== "" &&
			filename.trim() !== ""
		);
	}

	// Get signed URL from sessionStorage or fetch it from the API asynchronously
	public async getSignedUrl(
		keyOrFilename: string,
		expirationDuration: number = 3600
	): Promise<string> {
		// 1. Look up in sessionStorage first
		const storedUrl = this.getFromSessionStorage(keyOrFilename);
		if (storedUrl) {
			return storedUrl;
		}

		// 2. If not found or expired, fetch from API
		try {
			const fetchedUrl = await this.fetchFromApi(keyOrFilename);

			// 3. Store the new signed URL in sessionStorage with expiration metadata
			const expirationTime =
				this.getCurrentTimestamp() + expirationDuration;
			this.storeInSessionStorage(
				keyOrFilename,
				fetchedUrl,
				expirationTime
			);

			return fetchedUrl;
		} catch (error) {
			console.error("Error fetching signed URL:", error);
			throw error;
		}
	}
}

// Can we modify getSignedUrl so that it is more generic for other use case, maybe just passing all as key or absolute filename

// Get signed URL (first checks sessionStorage, else fetches from API)
async function fetchSignedUrl(
	keyOrFilename: string,
	apiUrl: string
): Promise<string> {
	try {
		const manager = new SessionStorageManager(apiUrl);

		const signedUrl = await manager.getSignedUrl(keyOrFilename, 3600);
		// console.log("Signed URL:", signedUrl);
		return signedUrl;
	} catch (error) {
		console.error("Failed to get signed URL:", error);
		throw error;
	}
}

// Example call
// fetchSignedUrl("123456789012", "form123", "document.pdf");

export { SessionStorageManager, fetchSignedUrl };
