import React, { ChangeEvent } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

type SelectFieldProps = {
  label?: string;
  name: string;
  value: any;
  id?: string;
  options?: string[];
  error?: string;
  helperText?: string;
  disabled?: boolean;
  onChange: (e: SelectChangeEvent<{ name?: string; value: unknown }>) => void;
  handleBlur?: () => void;
};

const SelectField: React.FC<SelectFieldProps> = (props) => {
  return (
    <>
      <FormControl
        variant="outlined"
        sx={{minWidth: 256 }}
        fullWidth={true}
        disabled={props.disabled}
        size="small"
      >
        <InputLabel htmlFor="outlined-age-native-simple">
          {props.label}
        </InputLabel>
        <Select
          native
          name={props.name}
          defaultValue={props.value}
          onBlur={props.handleBlur}
          onChange={props.onChange}
          label={props.label}
          autoWidth={true}
          inputProps={{
            name: props.name,
            id: props.id,
          }}
        >
          <option value={""} aria-label="None" />
          {props.options?.map((opt, index) => {
            return (
              <option key={index} value={opt}>
                {opt}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText>{props.helperText}</FormHelperText>
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </>
  );
};

SelectField.defaultProps = {
  label: "Drop down",
  name: "",
  options: ["Option 1", "Option 2"],
  error: "",
  helperText: "Helper text",
};


export default SelectField;
