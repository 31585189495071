import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormHelperText, CircularProgress, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SERVER_URL_IMG } from "../../config";
import { AdditionalSettings } from "../../models/FormField";
import { fetchSignedUrl } from "../../utils/SessionStorageManager";

interface UploadFieldProps {
	label?: string;
	name: string;
	value: any;
	error: any;
	formId: string;
	setFieldValue: (field: string, value: any) => void;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	helperText?: string;
	additionalSettings?: AdditionalSettings | undefined;
}

const generateFilenameTimestamped = (
	orginalFileName: string,
	isFormImage: boolean
): string => {
	const dotIndex = orginalFileName.lastIndexOf(".");

	// If no dot is found, set baseName as the whole file name and no extension
	const baseName =
		dotIndex !== -1
			? orginalFileName.substring(0, dotIndex)
			: orginalFileName;
	const extension =
		dotIndex !== -1 ? orginalFileName.substring(dotIndex) : ""; // If no extension, leave it empty

	let now = new Date();
	let timeString = now.toISOString().replace(/[-:TZ.]/g, ""); // Remove separators and dot

	// Create the new file name
	const newFileName = isFormImage
		? `${baseName}_${timeString}${extension}` // Appending timestamp before extension
		: orginalFileName;

	return newFileName;
};

const UploadField: React.FC<UploadFieldProps> = (props) => {
	const {
		value,
		formId,
		name,
		setFieldValue,
		handleBlur,
		disabled,
		error,
		additionalSettings,
	} = props;
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedFileType, setSelectedFileType] = useState<string>("");
	const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
	const [touched, setTouched] = useState<boolean>(false);
	const [isError, setIsError] = useState<string | boolean>(false);
	const [isFileSubmit, setIsFileSubmit] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isFileSubmitMsg, setIsFileSubmitMsg] = useState<boolean>(false);
	const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(null);
	const [imageDownloadURL, setImageDownloadURL] = useState<string>("");
	const FILE_SIZE = 10 * 1024 * 1024;
	const SUPPORTED_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
		"application/pdf",
	];
	const PREVIEW_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
	];
	const aadharNo = localStorage.getItem("id");
	const isFormImage =
		additionalSettings !== undefined &&
		additionalSettings?.uploadEndpoint &&
		additionalSettings?.fileRetrieveEndpoint;

	let keyOrFilename = isFormImage
		? `${formId}/${value}`
		: `${aadharNo}/${formId}/${value}`;
	let apiUrl = isFormImage
		? "/api/get_s3_form_fileURI"
		: "/api/get_s3_fileURI";

	const newFileName = generateFilenameTimestamped(name, true);

	let imageUploadURL = isFormImage
		? `${SERVER_URL_IMG}/api/upload3_form_file/${formId}/${newFileName}`
		: `${SERVER_URL_IMG}/api/uploads3/${aadharNo}/${formId}/${newFileName}`;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTouched(true);
		const file = e.target.files && e.target.files[0];
		if (file) {
			const orginalFileName = file.name.replace("C:\\fakepath\\", "");

			const newFileName = generateFilenameTimestamped(
				orginalFileName,
				isFormImage
			);

			const newFile = new File([file], newFileName, {
				type: file.type,
				lastModified: file.lastModified,
			});

			setFieldValue(name, newFileName);
			setIsFileSubmit(true);
			setSelectedFileSize(newFile.size);
			setSelectedFileType(newFile.type);
			setSelectedFile(newFile);
		} else {
			setFieldValue(name, undefined);
		}
	};

	if (value && !imageDownloadURL) {
		fetchSignedUrl(keyOrFilename, apiUrl)
			.then((url) => {
				// Use the signed URL here
				// console.log(url);
				setImageDownloadURL(url);
			})
			.catch((error) => {
				console.error("Early retrieving URL:");
				console.log(error);
			});
	}

	useEffect(() => {
		if (touched && isFileSubmit) {
			setIsError(false);
			if (!value) {
				setIsError("Photo is required");
			} else if (
				!selectedFileType ||
				!SUPPORTED_FORMATS.includes(selectedFileType)
			) {
				setIsError(`File Type: ${selectedFileType}, Invalid File Type`);
			} else if (!(selectedFileSize && selectedFileSize <= FILE_SIZE)) {
				setIsError(
					`File Size: ${Math.floor(
						selectedFileSize / 1024 / 1024
					)}MB, File size should not be more than ${
						FILE_SIZE / 1024 / 1024
					}MB`
				);
			} else {
				setIsUploading(true);
				const formData = new FormData();
				formData.append("File", selectedFile!);
				fetch(imageUploadURL, {
					method: "POST",
					body: formData,
				})
					.then((response) => response.json())
					.then((result) => {
						keyOrFilename = isFormImage
							? `${formId}/${result["filename"]}`
							: `${aadharNo}/${formId}/${result["filename"]}`;
						fetchSignedUrl(keyOrFilename, apiUrl)
							.then((url) => {
								setImageDownloadURL(url);
							})
							.catch((error1) => {
								console.error("Error retrieving URL:");
								console.log(error);
							});

						setLastUpdatedTime(moment().toString());
						setFieldValue(name, result["filename"]);
						setIsFileSubmitMsg(true);
						setIsUploading(false);
					})
					.catch((error1) => {
						setIsUploading(false);
					});
			}
			setIsFileSubmit(false);
		}
	}, [selectedFileType, selectedFileSize, value]);

	const CustomImageContainer = () => {
		if (
			value &&
			PREVIEW_FORMATS.includes(selectedFileType) &&
			isFileSubmitMsg
		) {
			return (
				<Box sx={{ overflow: "hidden" }}>
					<img
						src={imageDownloadURL}
						alt="Loading"
						style={{
							maxWidth: "100%",
							height: "auto",
							display: "block",
						}}
					/>
				</Box>
			);
		}
		if (value) {
			if (isUploading) {
				return (
					<div style={{ margin: 10 }}>
						<CircularProgress />
					</div>
				);
			}
			if (value.includes(".pdf")) {
				return (
					<a href={imageDownloadURL} target="_blank" rel="noreferrer">
						{"Click to view " + value}
					</a>
				);
			}
			return (
				<Box sx={{ overflow: "hidden", padding: 0.5 }}>
					<img
						src={imageDownloadURL}
						alt="Loading"
						style={{
							maxWidth: "100%",
							height: "auto",
							display: "block",
						}}
					/>
				</Box>
			);
		}
		return null;
	};

	return (
		<div>
			<Box>
				<Box sx={{ marginBottom: 1 }}>{props.label}</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "wrap",
					}}
				>
					<input
						type="file"
						name={name}
						id="contained-button-file"
						onBlur={handleBlur}
						onChange={(e) => {
							handleChange(e); // Call the change handler
							e.target.value = ""; // Reset the input value after file is selected
						}}
						multiple={false}
						disabled={disabled}
					/>

					<CustomImageContainer />

					{isFileSubmitMsg && (
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<CheckCircleIcon
								style={{
									color: "green",
									fontSize: 15,
									marginTop: 5,
									marginRight: 5,
								}}
							/>
							<FormHelperText>
								{"File Submitted Successfully"}
							</FormHelperText>
						</Box>
					)}
				</Box>
				<FormHelperText>{props.helperText}</FormHelperText>
				<FormHelperText error={true}>
					{isError ? isError : error}
				</FormHelperText>
			</Box>
			<hr />
		</div>
	);
};

UploadField.defaultProps = {
	label: "File upload",
	name: "",
	value: "",
	error: "",
	helperText: "Helper text",
};

export default UploadField;
