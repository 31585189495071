import React from "react";
import {
	Stepper,
	Step,
	StepLabel,
	Typography,
	Button,
	Box,
	Card,
	CardContent,
} from "@mui/material";
import ResponsiveTypography from "../../components/ResponsiveTypography";

interface WebStepperProps {
	children: React.ReactNode;
	steps: string[];
	application_name: string;
	isStepperRequired: boolean;
	activeStep: number;
	handleNext: () => void;
	handleBack: () => void;
	isStepOptional?: (step: number) => boolean;
	isStepSkipped?: (step: number) => boolean;
	nextButtonName: string;
	backButtonName: string;
}

const WebStepper: React.FC<WebStepperProps> = ({
	children,
	steps,
	application_name,
	isStepperRequired,
	activeStep,
	handleNext,
	handleBack,
	isStepOptional,
	isStepSkipped,
	nextButtonName,
	backButtonName,
}) => {
	return (
		<>
			<Card
				sx={{
					mb: 0.5,
					borderRadius: 1,
					marginLeft: "auto",
					marginRight: "auto",
					"@media (max-width: 960px)": {
						width: "100%",
					},
				}}
			>
				<CardContent>
					{application_name && (
						<ResponsiveTypography>
							{application_name}
						</ResponsiveTypography>
					)}
					{isStepperRequired && (
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label, index) => {
								const stepProps: { completed?: boolean } = {};
								const labelProps: {
									optional?: React.ReactNode;
								} = {};

								if (isStepOptional && isStepOptional(index)) {
									labelProps.optional = (
										<Typography variant="caption">
											Optional
										</Typography>
									);
								}
								if (isStepSkipped && isStepSkipped(index)) {
									stepProps.completed = false;
								}
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>
											{label}
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					)}
				</CardContent>
			</Card>
			{children}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row-reverse",
					mt: 2,
				}}
			>
				<Button
					size="small"
					color="primary"
					variant="contained"
					sx={{ marginRight: (theme) => theme.spacing(1) }}
					onClick={handleNext}
				>
					{nextButtonName}
				</Button>
				<Button
					size="small"
					onClick={handleBack}
					disabled={activeStep === 0}
					sx={{ marginRight: (theme) => theme.spacing(1) }}
				>
					{backButtonName}
				</Button>
			</Box>
		</>
	);
};

export default WebStepper;
