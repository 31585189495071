import { PaymentHandler } from "@anginn/flexi-form";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as applicantActions from "../../store/actions/applicant";
import RazorpayButton from "./RazorpayButton";
import { Box, Typography } from "@mui/material";
import HourglassBottomTwoToneIcon from "@mui/icons-material/HourglassBottomTwoTone";
import AutorenewTwoToneIcon from "@mui/icons-material/AutorenewTwoTone";
import AirTwoToneIcon from "@mui/icons-material/AirTwoTone";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";

const PostFinalSubmitPaymentHandler = (props) => {
	const { application } = props;

	const [canCheckOut, setCanCheckOut] = useState(false);

	const paymentHandler = useSelector((state) => state.applicant.payments);

	const transaction = useSelector((state) => state.applicant.transaction);

	const aadharNo = "654632165843";
	const formId = "donation2024";
	const amount = 250000;

	const dispatch = useDispatch();

	let isApproved = false;
	if (application.approvals) {
		isApproved = application.approvals.isApproved();
	}

	// Initialize PaymentHandler if undefined
	useEffect(() => {
		if (isApproved && paymentHandler === undefined) {
			dispatch(
				applicantActions.initialPayments(
					[],
					false,
					aadharNo,
					formId,
					amount
				)
			);
		}
		// setTransaction(pendingTransaction); // Set state to trigger a re-render
	}, [paymentHandler, dispatch]);

	// Handle order placement and checkout state
	useEffect(() => {
		if (isApproved && !!paymentHandler && transaction) {
			if (transaction.canPlaceOrder()) {
				dispatch(
					applicantActions.placePaymentOrder(
						transaction.transactionId,
						aadharNo,
						formId,
						amount
					)
				);
			}
			// console.log("canCheckOut", transaction.canCheckOut());

			setCanCheckOut(transaction.canCheckOut());
		}
	}, [paymentHandler, transaction, dispatch]);

	if (!isApproved) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<HourglassBottomTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Awaiting Approval for your membership
				</Typography>
			</Box>
		);
	}

	if (!paymentHandler) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<AutorenewTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Loading Payments
				</Typography>
			</Box>
		);
	}

	if (!transaction) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<AirTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Payments Complete!!!
				</Typography>
			</Box>
		);
	}

	const checkOutSuccessCallback = (response) => {
		setCanCheckOut(false);

		paymentHandler.updateCheckOutSuccess(
			transaction.transactionId,
			response
		);
		dispatch(applicantActions.resetTransaction());
	};

	const updateCheckOutFailure = (response) => {
		setCanCheckOut(false);
		paymentHandler.updateCheckOutFailure(
			transaction.transactionId,
			response
		);

		dispatch(applicantActions.resetTransaction());
	};

	// console.log(paymentHandler.hash(), transaction.hash(), paymentHandler);
	// console.log(transaction, transaction.canCheckOut(), canCheckOut);

	if (canCheckOut) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<ThumbUpAltTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Membership Approved
				</Typography>
				<br></br>
				<RazorpayButton
					transaction={transaction}
					checkOutSuccessCallback={checkOutSuccessCallback}
					updateCheckOutFailure={updateCheckOutFailure}
				/>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<AutorenewTwoToneIcon
				sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
			/>
			<Typography component="h1" variant="h4">
				Fetching Details
			</Typography>
		</Box>
	);
};

export { PostFinalSubmitPaymentHandler };
// Try some other approach, still transaction object seems to be stale
