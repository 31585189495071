const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const checkout = [
	{
		id: "asdfasd",
		label: "Name",
		type: "statictext",
		validationType: "string",
		value: "",
		isEditableProfile: false,
		action: [
			{ type: "reference", field: "name" },
			{ type: "append", field: "fatherName" },
			{ type: "append", field: "lastName" },
		],
	},
	{
		id: "sdfsg",
		label: "Email",
		placeholder: "Enter Amount to Donate",
		type: "statictext",
		validationType: "string",
		value: "",
		action: [{ type: "reference", field: "email" }],
	},
	{
		id: "dsfgasd",
		label: "Donation Amount",
		type: "statictext",
		validationType: "string",
		value: "",
		action: [{ type: "reference", field: "donationAmt" }],
	},
];
