import moment from "moment";
import { ApplicationForm, Template, CLAIMS } from "@anginn/flexi-form";

import { pg1 } from "@form-data/Donation/donation";
import { checkout } from "@form-data/Donation/checkout";

const formData = {
	id: "donation2024",
	name: "Donate to SVGA",
	subtitle: "Click apply for donating an amount",
	headerImage: "LaptopBanner3.png",
	templates: {
		0: {
			name: "Donation",
			template: new Template("gfrt", pg1, "", "Proceed to Checkout"),
			preview: true,
		},
		1: {
			name: "Check Out",
			template: new Template("gfrt", checkout, "", "Pay"),
			preview: true,
		},
	},
	start_time: moment("26/7/2024 00:00", "DD/MM/YYYY HH:mm"),
	end_time: moment("4/8/2026 23:59", "DD/MM/YYYY HH:mm"),
};

const getApplication = () => {
	const temp = Object.assign(new ApplicationForm(), formData);
	temp.parseObject();
	return temp;
};

export const form = getApplication;
