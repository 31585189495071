const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const pg2 = [
	{
		id: "graduation",
		label: "Graduation *",
		placeholder: "Engineer / Architect / BCom",
		helperText: "",
		type: "condSelect",
		validationType: "string",
		value: "",
		options: [
			"Engineering - BE / BTech",
			"Architect",
			"Interior Designing",
			"Bcom",
			"BA",
			"BSc",
			"Medical",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Graduation is required"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "graduationCourse",
		label: "Graduation course *",
		placeholder: "Computers / AI-ML / ML-DS / Electronics",
		helperText: "",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Graduation course is required"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "isPostGraduate",
		label: "Completed Post Graduation? *",
		placeholder: "",
		helperText: "Yes, if passed your post graduation",
		type: "select",
		validationType: "string",
		value: "No",
		options: ["Yes", "No"],
		validations: [
			{
				type: "required",
				params: ["Select valid option"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "postGraduation",
		label: "Post Graduation *",
		placeholder: "Engineer / Architect / BCom",
		helperText: "",
		type: "condSelect",
		validationType: "string",
		value: "",
		options: [
			"Engineering - ME / MTech",
			"Medical",
			"Msc",
			"Mcom",
			"MA",
			"BSc",
			"PhD",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Post Graduation is required"],
			},
		],
		action: [{ type: "required" }],
		rules: { or: [{ "==": [{ var: "isPostGraduate" }, "Yes"] }] },
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "postGraduationCourse",
		label: "Post Graduation Specialization *",
		placeholder: "DataScience / Economics / Cardiac Surgeon",
		helperText: "Specify the specialization",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Post Graduation Specialization is required"],
			},
		],
		action: [{ type: "required" }],
		rules: { or: [{ "==": [{ var: "isPostGraduate" }, "Yes"] }] },
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "currentProfesssion",
		label: "Current Business / Professsion *",
		placeholder: "Engineer / Architect / BCom",
		helperText: "",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Current Business / Professsion is required"],
			},
		],
	},
	{
		id: "currentPosition",
		label: "Current Position *",
		placeholder: "Manager / CEO / Consultant",
		helperText: "",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Current Position is required"],
			},
		],
	},
	{
		id: "extraCurricular",
		label: "Extra Curricular Activities / Hobbies",
		placeholder: "",
		helperText: "",
		type: "textarea",
		validationType: "string",
		value: "",
		validations: [],
	},
	{
		id: "otherSocialAssociation",
		label: "Association with Other Social Organization",
		placeholder: "",
		helperText: "",
		type: "textarea",
		validationType: "string",
		value: "",
		validations: [],
	},
];
