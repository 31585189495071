import React, { useMemo } from "react";
import { DynamicForm } from "@anginn/flexi-form";
import { useSelector } from "react-redux";
import { parseISOformat } from "../utils/AppDateFormatter";
import { PostFinalSubmitPaymentHandler } from "../utils/payments/PostFinalSubmitPaymentHandler";

const MyCustomFinalSubmit = ({ application }) => {
	const submittedOn = application.submittedOn;

	return (
		<div>
			<h2>Thank you for submitting the form!</h2>
			{!!submittedOn && (
				<>
					<p>
						We received your last response on{" "}
						{parseISOformat(submittedOn)}.
					</p>
					<p>ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે</p>
				</>
			)}
		</div>
	);
};

export default function FlexiForm(props) {
	const { jsonData, formId } = props;
	const profile = useSelector((state) => state.applicant.applicant.profile);

	// Memoize current step to prevent re-renders
	const currentStep = useMemo(() => jsonData.getCurrentStep(), [jsonData]);

	return (
		<DynamicForm
			formId={formId}
			application={jsonData}
			cbSubmit={props.handleSubmission}
			FinalSubmitComponent={PostFinalSubmitPaymentHandler}
			profile={profile}
			currentStep={currentStep}
		/>
	);
}
