import * as React from "react";
import {
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormHelperText,
	FormControl,
	FormLabel,
} from "@mui/material";

interface CheckboxFieldProps {
	label?: string;
	name: string;
	options?: string[];
	error?: any;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	setFieldValue: (name: string, value: string) => void;
	value?: string;
	handleBlur?: () => void;
	disabled?: boolean;
	helperText?: string;
}

const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
	const [checkedItems, setCheckedItems] = React.useState<string[]>([]);

	React.useEffect(() => {
		// Initialize checkedItems from props.value
		if (props.value) {
			setCheckedItems(props.value.split("NA&&"));
		}
	}, [props.value]);

	const handleCheckItem = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		let items = [...checkedItems];

		if (items.includes(value)) {
			items = items.filter((item) => item !== value);
		} else {
			items.push(value);
		}
		setCheckedItems(items);
		props.setFieldValue(props.name, items.join("NA&&"));
	};

	const isChecked = (option: string) => {
		return checkedItems.includes(option);
	};

	return (
		<FormControl component="fieldset" variant="standard">
			<FormLabel component="legend">{props.label}</FormLabel>
			<FormGroup>
				{props.options?.map((opt, index) => {
					return (
						<FormControlLabel
							key={index}
							control={
								<Checkbox
									checked={isChecked(opt)}
									onChange={handleCheckItem}
									name={props.name + "-" + index}
									onBlur={props.handleBlur}
									value={opt}
									color="primary"
									disabled={props.disabled}
									size="small"
								/>
							}
							label={opt}
						/>
					);
				})}
				<FormHelperText>{props.helperText}</FormHelperText>
				<FormHelperText error={!!props.error}>
					{props.error}
				</FormHelperText>
			</FormGroup>
		</FormControl>
	);
};

CheckboxField.defaultProps = {
  label: "Checkbox",
  name: "",
  options: ["Option 1", "Option 2"],
  error: "",
  helperText: "Helper text",
};

export default CheckboxField;
