// src/models/ApplicationForm.ts

import moment, { Moment } from "moment";
import { Template } from "./Template";
import { Approvals } from "./Approvals";
import { ApplicationFormApprovalsMixin } from "./ApplicationFormApprovalsMixin";
import { ApproverResponse, ApproversResponses } from "./ApproversResponses";
import { FormField } from "./FormField";
import { SERVER_URL_IMG } from "../config";
import { PaymentHandler } from "./PaymentHandler";

// FormState should be used for form-builder
const FormState = {
	NEW: "NEW",
	INCOMPLETE: "INCOMPLETE",
	READY: "READY", // ready to publish
	PUBLISHED: "PUBLISHED",
	FINISHED: "FINISHED",
} as const;

type FormStateType = (typeof FormState)[keyof typeof FormState];

const FormResponseState = {
	UPCOMING: "Upcoming",
	ACTIVE: "Active",
	DECLINE: "Decline",
	EXPIRED: "Expired",
	OPEN: "Open",
};

type Templates = Record<
	string,
	{
		template: Template;
		name?: string;
		preview?: boolean;
	}
>;

type FormFieldsRecords = Record<string, FormField>;

type HeaderImage = string | Record<string, string>;

class ApplicationForm extends ApplicationFormApprovalsMixin {
	#fieldId_templateId_index: Record<string, string> = {};

	constructor(
		public id: string,
		public name: string,
		public subtitle: string = "",
		public templates: Templates = {},
		public start_time: string = "",
		public end_time: string = "",
		public pretext?: any,
		public eligibility?: any,
		public isActive: boolean = true,
		public submitted: boolean = false,
		public state: FormStateType = FormState.NEW,
		public activeStep: number = 0,
		public approvals?: Approvals,
		public submittedOn?: Moment,
		public publishedOn?: Moment,
		public lastUpdated?: Moment,
		public isEditableByApplicant: boolean = true,
		public headerImage: HeaderImage = "",
		public changeLog: Array<{
			fieldId: string;
			previousValue: string;
			newValue: string;
			timestamp: string;
		}> = [],
		public auditLog: Array<{
			property: string;
			previousValue: any;
			newValue: any;
			timestamp: string;
		}> = [],
		public payments?: PaymentHandler
	) {
		super();
		this.createdOn = moment();
		this.parseObject();
	}

	private createdOn: Moment;

	parseObject() {
		const temp: Templates = {};
		this.#fieldId_templateId_index = {};
		for (const index in Object.keys(this.templates)) {
			const key = Object.keys(this.templates)[index];
			const template = this.templates[key].template;
			const parsedTemplate = new Template(
				template.id,
				template.fields,
				template.title,
				template?.nextButtonName,
				template?.backButtonName
			);
			temp[key] = {
				template: parsedTemplate,
				name: this.templates[key].name,
				preview: this.templates[key].preview,
			};

			// Populate fieldId_templateId_index during template parsing
			for (const fieldId in parsedTemplate.getFieldsIndex()) {
				this.#fieldId_templateId_index[fieldId] = key;
			}
		}
		this.templates = temp;
		this.parseApprovals();
		if (this.payments !== undefined) {
			this.payments == PaymentHandler.fromJSON(this.payments);
		}
	}

	submit() {
		this.submitted = true;
		this.submittedOn = moment();
	}

	getTemplateList(): Template[] {
		const temp = [];
		for (let index in Object.keys(this.templates)) {
			temp.push(this.templates[index].template);
		}
		return temp;
	}

	getStepList(): string[] {
		const temp: string[] = [];
		for (let index in this.templates) {
			temp.push(this.templates[index].name || "");
		}
		return temp;
	}

	getTemplate(templateIndex: string): Template {
		// templateIndex is not templateId
		return this.templates[templateIndex].template;
	}

	getField(fieldId: string): FormField | null {
		const templateIndex = this.#fieldId_templateId_index[fieldId];
		if (!templateIndex) {
			return null;
		}
		const template = this.getTemplate(templateIndex);
		return template.getField(fieldId);
	}

	nextStep() {
		this.activeStep = this.activeStep + 1;
	}

	prevStep() {
		this.activeStep = this.activeStep - 1;
	}

	getCurrentStep(): number {
		const ttlSteps = this.getStepList().length;
		if (this.activeStep >= ttlSteps) {
			return ttlSteps - 1;
		}
		return this.activeStep;
	}

	setHeaderImage(image: HeaderImage) {
		this.headerImage = image;
	}

	getHeaderImage(templateId?: string): string {
		if (typeof this.headerImage === "string") {
			return `${this.id}/${this.headerImage}`;
		} else {
			Error("Does not support currently");
			return this.headerImage[templateId || ""] || "";
		}
	}

	removeHeaderImage(templateId?: string) {
		if (!templateId || typeof this.headerImage === "string") {
			this.headerImage = "";
		} else {
			delete this.headerImage[templateId];
		}
	}

	updateTemplate(index: number, template: Template) {
		if (this.templates[index]) {
			this.templates[index].template = template;
		}
	}

	updateFieldProperties(fieldProperties: any) {
		const templateIndex =
			this.#fieldId_templateId_index[fieldProperties.id];

		if (!templateIndex) {
			return;
		}
		const template = this.getTemplate(templateIndex);
		template.updateFieldProperties(fieldProperties);
	}

	/**
	 * @deprecated The method should not be used
	 */
	updateFieldPropertiesOld(fieldProperties: any) {
		for (let index in this.templates) {
			let template = this.templates[index].template;
			template.updateFieldProperties(fieldProperties);
		}
	}

	updateTemplateName(templateIndex: string, name: string) {
		if (templateIndex in Object.keys(this.templates)) {
			this.templates[templateIndex].name = name;
		}
	}

	updateValues(application_values: any) {
		// application_values.values;
		for (let index in this.templates) {
			let template = this.templates[index].template;
			template.update_default_values(application_values);
		}
		// update values in template
	}

	getFlatternValues(): { [key: string]: any } {
		let values: { [key: string]: any } = {};
		for (let index in this.templates) {
			let template = this.templates[index].template;
			values = { ...template.getValues(), ...values };
		}
		return values;
	}

	getFormFieldsValues(
		returnDict: boolean = false
	): FormField[] | FormFieldsRecords {
		let values: FormField[] = [];
		for (let index in this.templates) {
			let template = this.templates[index].template;
			if (index !== "0") {
				// chance of a bug, as index is considerred as string which may not be true always
				values.push(FormField.createFormFieldSpacer(template.id));
			}

			for (let field of template.fields) {
				values.push(field);
			}
		}
		return returnDict
			? values.reduce((acc, field) => {
					acc[field.id] = field;
					return acc;
			  }, {} as FormFieldsRecords)
			: values;
	}

	getHeaderValues() {
		return {
			id: this.id,
			name: this.name,
			start_time: this.start_time,
			end_time: this.end_time,
			state: this.state,
			publishedOn: this.publishedOn,
			lastUpdated: this.lastUpdated,
			isActive: this.isActive,
			subtitle: this.subtitle,
		};
	}

	getSelectedValues(selectedColumns: string[]): { [key: string]: any } {
		let values = this.getFlatternValues();
		const filteredValues: { [key: string]: any } = {}; // Use type assertion here

		selectedColumns.forEach((key) => {
			if (values.hasOwnProperty(key)) {
				filteredValues[key] = values[key];
			}
		});

		return filteredValues;
	}

	getFormHelperText(isUnSubmitted: boolean = false) {
		if (this.submitted) {
			return `Form successfully submitted on ${moment(
				this.submittedOn
			).format(
				"DD-MMM-YYYY h:mm a"
			)} ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે`;
		} else if (isUnSubmitted) {
			if (this.approvals && this.isEditingUponDecline()) {
				return (
					"Application Declined, resubmit allowed : " +
					this.approvals.getApplicantMessage()
				);
			}
			if (!this.end_time) {
				return "Un-submitted Form";
			}
			return `Un-submitted Form, Expiring on ${moment(
				this.end_time
			).format("DD-MMM-YYYY h:mm a")}`;
		} else if (moment(this.start_time) > moment()) {
			return `Application starting from ${moment(this.start_time).format(
				"DD-MMM-YYYY h:mm a"
			)}`;
		} else if (!this.end_time) {
			return "";
		} else if (moment(this.end_time) < moment()) {
			return "Form has expired";
		} else {
			return `Last date of application: ${moment(this.end_time).format(
				"DD-MMM-YYYY h:mm a"
			)}`;
		}
	}

	isEditingUponDecline(): boolean {
		if (
			!this.isFinalSubmit() &&
			this.approvals &&
			this.approvals.isEditingUponDecline()
		) {
			return true;
		}
		return false;
	}

	getFormResponseStatus(isApplied: boolean): string {
		if (!isApplied) {
			// Form has not been applied by the applicant
			if (moment(this.start_time) > moment()) {
				return FormResponseState.UPCOMING; // Application has not started yet
			} else if (moment(this.end_time) < moment()) {
				return FormResponseState.EXPIRED; // Application period has expired
			} else {
				return FormResponseState.OPEN; // Application is currently open
			}
		} else {
			// Form has been applied by the applicant
			if (moment(this.end_time) < moment()) {
				if (this.isEditingUponDecline()) {
					return FormResponseState.DECLINE;
				}
				return FormResponseState.EXPIRED; // Application period has expired
			} else {
				return FormResponseState.ACTIVE; // Application is currently active
			}
		}
	}

	isFinalSubmit() {
		return this.submitted && !this.isEditableByApplicant;
	}

	setParameters(application_form_values: any) {
		this.isActive = application_form_values.isActive;
		this.submitted = application_form_values.submitted;
		this.submittedOn = application_form_values.submittedOn;
		this.isEditableByApplicant =
			application_form_values.isEditableByApplicant;
		this.createdOn = application_form_values.createdOn;
		this.activeStep = application_form_values.activeStep;
		this.eligibility = application_form_values.eligibility;
		this.changeLog = application_form_values.changeLog;
		this.auditLog = application_form_values.auditLog;
		this.setApproversResponses(application_form_values.approvalResponses);
	}
}

export { ApplicationForm, FormState, FormResponseState };
