import { FIREBASE_URL, SERVER_URL } from "../../config";
import { ProfileField, Applicant, PaymentHandler } from "@anginn/flexi-form";
import { LOGOUT } from "./auth";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_USER = "SET_USER";

export const updateTemplate = (key, template, formId, isSubmit) => {
	console.log("updateTemplate");
	return async (dispatch, getState) => {
		const applicant = getState().applicant.applicant;
		applicant.updateApplicationTemplate(key, template, formId, isSubmit);
		const ACCESS_TOKEN = getState().auth.token;
		applicant.performNext(ACCESS_TOKEN);
		const applicant_id = applicant.id;
		if (isSubmit) {
			console.log("Sending an email");
			let response = await fetch(
				`${SERVER_URL}/api/emailTemplate/${applicant_id}/${formId}`
			);

			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
		}
		try {
			const response = await fetch(
				`${SERVER_URL}/api/addMapping/${applicant_id}/${formId}`
			);
			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
		} catch (err1) {
			console.log(err1);
		}

		try {
			dispatch({ type: UPDATE_TEMPLATE, applicant });
		} catch (err) {
			throw err;
		}
	};
};

export const fetchApplicant = (id, contact, token = undefined) => {
	console.log("fetchApplicant");
	return async (dispatch, getState) => {
		if (id != null) {
			// console.log(`${FIREBASE_URL}applicants/${id}.json`);
			let ACCESS_TOKEN = token;
			if (ACCESS_TOKEN === undefined) {
				ACCESS_TOKEN = getState().auth.token;
			}
			if (ACCESS_TOKEN == null) {
				return;
			}
			const response = await fetch(
				`${FIREBASE_URL}applicants/${id}.json?auth=${ACCESS_TOKEN}`
			);

			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}

			const resData = await response.json();
			const applicant = Object.assign(new Applicant(), resData);
			applicant.id = id;
			applicant.aadhaar_no = id;
			applicant.contact = contact;
			// applicant.applications = {}; // assume to be blank
			applicant.parseObject();
			if (resData == null) {
				const profile = {
					phone_number: new ProfileField(
						"phone_number",
						"Phone Number",
						parseInt(contact),
						false,
						undefined,
						true
					),
					aadhar_number: new ProfileField(
						"aadhar_number",
						"User ID",
						parseInt(id),
						false,
						undefined,
						true
					),
				};
				applicant.profile = profile;
				applicant.performNext(ACCESS_TOKEN);
			}
			dispatch({ type: UPDATE_TEMPLATE, applicant });
		}
	};
};

export const initialPayments = (
	transactions,
	allowMultipleTransactions,
	aadharNo,
	formId,
	amount
) => {
	console.log("initialPayments");
	return async (dispatch, getState) => {
		try {
			const paymentHandler = new PaymentHandler(
				transactions,
				allowMultipleTransactions
			);
			let txn = paymentHandler.getLastPendingTransaction();
			if (!txn) {
				txn = paymentHandler.createTransaction(
					aadharNo,
					formId,
					amount,
					"INR"
				);
			}

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const placePaymentOrder = (transactionId, aadharNo, formId, amount) => {
	console.log("placePaymentOrder");
	return async (dispatch, getState) => {
		try {
			const response = await fetch(`${SERVER_URL}/api/razorpay/order`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					aadharNo,
					formId,
					amount,
					receipt: "13532rfw",
				}),
			});
			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
			const resData = await response.json();

			const paymentHandler = getState().applicant.payments;
			console.log("hash before", paymentHandler.hash());

			paymentHandler.updatePlacedOrder(transactionId, resData);
			console.log("hash after", paymentHandler.hash());

			let txn = paymentHandler.getLastPendingTransaction();

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});

			console.log(resData);
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const resetTransaction = () => {
	console.log("resetTransaction");
	return async (dispatch, getState) => {
		try {
			const paymentHandler = getState().applicant.payments;
			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: undefined,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const setUser = (id) => {
	return async (dispatch, getState) => {
		dispatch({ type: SET_USER, id });
	};
};
